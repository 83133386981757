import React, { useState, useEffect } from 'react';

import './collapsable.scss';

const Collapsable = ({ title, collapsed = true, children }) => {
    const initialState = {
        selected: !collapsed,
    };

    const [selected, setSelected] = useState(initialState);

    const toggleSelected = () => {
        setSelected(!selected);
    };

    useEffect(() => {
        setSelected(!collapsed);
    }, collapsed)

    const handleKeyDown = ev => {
        if (ev.keyCode == 13) {
            this.toggleSelected();
        }
    };

    return (
        <div className="collapsable">
            <div
                role="button"
                tabIndex="0"
                className={
                    'collapsable-title ' + (selected ? 'inactive' : 'active')
                }
                onClick={toggleSelected}
                onKeyDown={handleKeyDown}
            >
                <span>{title}</span>
            </div>
            <div className={'collapsable-body ' + (selected ? 'hidden' : '')}>
                {children}
            </div>
        </div>
    );
};

export default Collapsable;
