// This component is meant to return a link wrapping the component's children
// that will be based on the URI in the frontend config and a slug created from
// the promotion's name and the drawing group's name. The link will go to the
// portal page for winners.
//
// Required parameters --
// urlBase (from the config), promoName, drawingGroupName and children (being wrapped by the link)

import React from 'react';
import slugify from 'react-slugify';

const WinnersPortalLink = ({ urlBase, promoName, children }) => {
    // don't render a link if there's anything falsy about the urlBase
    if (!urlBase) {
        return <>{children}</>;
    } else {
        const promoUrl = urlBase + '/' + slugify(promoName) + '-winners';
        return (
            <a href={promoUrl} rel='noopener noreferrer'>
                {children}
            </a>
        );
    }
};

export default WinnersPortalLink;
