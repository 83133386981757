import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../components/layouts/Layout';
import SEO from '../components/seo';
import Collapsable from '../components/widgets/collapsable';
import BackButton from '../components/widgets/back';
import WinnersPortalLink from '../components/winners/winners_portal_link';

import '../components/sweeps/style.scss';

import { getHTML, getText } from '../utils/cms_copy_provider';
import isBrowser from '../utils/isBrowser';

export const Sweeps = ({ user, sweep, notification, config, actions }) => {
    const queryString = isBrowser() ? window.location.search : '';
    const urlParams = new URLSearchParams(queryString);
    const preselection = {
        sweepId: urlParams.get('sweep_id'),
        drawGroupId: urlParams.get('draw_group_id'),
    };

    return (
        <Layout user={user} config={config} notification={notification} actions={actions}>
            <SEO title='Sweepstakes' />

            <div className='container-fluid sweeps primary-page-content'>
                <div className='row'>
                    <div className='col-8 offset-2'>
                        <BackButton />
                        <div className='col description'>
                            <h2>{getText('sweeps_title')}</h2>
                            <p>{getHTML('sweeps_description')}</p>
                        </div>
                    </div>
                </div>
                <SweepsInner config={config} preselection={preselection} sweep={sweep} actions={actions} />
            </div>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Sweeps);

class SweepsInner extends React.Component {
    componentDidMount() {
        this.props.actions.sweepActions.getSweeps();
    }

    render() {
        const sweeps = this.props.sweep.sweeps || [];

        const drawGroupRender = (name, drawing_groups, drawGroupId) => {
            if (drawing_groups.length) {
                return drawing_groups.map((drawingGroup, index) => {
                    return (
                        <DrawingGroup
                            key={drawingGroup.quantum_start}
                            config={this.props.config}
                            promoName={name}
                            drawingGroup={drawingGroup}
                            index={index}
                            selection={drawGroupId == drawingGroup.id}
                        />
                    );
                });
            } else {
                return <div>{getText('sweeps_msg_nodrawinggroups')}</div>;
            }
        };

        if (sweeps && sweeps.length) {
            return (
                <div className='container-fluid sweep-list'>
                    <div className='col-lg-10 offset-lg-1'>
                        {sweeps.map(sweep => {
                            return (
                                <Collapsable key={sweep.name} title={sweep.name} collapsed={sweep.id == this.props.preselection.sweepId}>
                                    {drawGroupRender(sweep.name, sweep.drawing_groups, this.props.preselection.drawGroupId)}
                                </Collapsable>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return <div className='container-fluid sweep-list empty'>{getText('sweeps_msg_nosweepstakes')}</div>;
        }
    }
}

const DrawingGroup = ({ config, promoName, drawingGroup, index, selection }) => {
    const initialState = selection;

    const [selected, setSelected] = useState(initialState);

    const toggleSelected = () => {
        setSelected(!selected);
    };

    useEffect(() => {
        setSelected(selection || drawingGroup.drawings.length === 1);
    }, [drawingGroup.drawings, selection]);

    const handleKeyDown = ev => {
        if (ev.keyCode == 13) {
            this.toggleSelected();
        }
    };

    const titles = index => {
        if (index === 0) {
            return (
                <div className='row titles'>
                    <div className='col-2'></div>
                    <div className='col-3'>{getText('sweeps_column_prizes')}</div>
                    <div className='col-3'>{getText('sweeps_column_entryperiod')}</div>
                    <div className='col-2'>{getText('sweeps_column_drawingdate')}</div>
                    <div className='col-2'>{getText('sweeps_column_numberofentries')}</div>
                </div>
            );
        } else {
            return null;
        }
    };

    const showHide = drawingCount => {
        if (drawingCount > 1) {
            return (
                <div className={'toggle-drawings '} onClick={toggleSelected} onKeyDown={handleKeyDown} role='button' tabIndex='0'>
                    {!selected ? getText('sweeps_btn_showdrawings') : getText('sweeps_btn_hidedrawings')}
                </div>
            );
        } else {
            return null;
        }
    };

    return (
        <div className=''>
            {titles(index)}
            <div className='row group-listing'>
                <div className='col-2 group-name'>
                    {drawingGroup.name}
                    {showHide(drawingGroup.drawings.length)}
                </div>
                <div className='col-3'>
                    {drawingGroup.prizes.map(prize => {
                        return (
                            <div key={prize.name} className='prize'>
                                {prize.name}
                            </div>
                        );
                    })}
                </div>
                <div className='col-3'>
                    {new Date(drawingGroup.quantum_start * 1000).toDateString()}
                    {' - '}
                    {new Date(drawingGroup.quantum_end * 1000).toDateString()}
                </div>
                <div className='col-2'></div>
                <div className='col-2'></div>
            </div>
            <div className={'container drawings ' + (!selected ? 'd-none' : '')}>
                {drawingGroup.drawings.map(drawing => {
                    return <Drawing config={config} promoName={promoName} key={drawing.id} drawing={drawing} drawingGroupName={drawingGroup.name} />;
                })}
            </div>
        </div>
    );
};

const Drawing = ({ config, promoName, drawing, drawingGroupName }) => {
    return (
        <div className='row drawing-row'>
            <div className='col-2 empty-spot'></div>
            <div className='col-2'>{'Drawing ' + drawing.id}</div>
            <div className='col-1'>&nbsp;</div>
            <div className='col-3'>
                {new Date(drawing.quantum_start * 1000).toDateString()}
                {' - '}
                {new Date(drawing.quantum_end * 1000).toDateString()}
            </div>
            <div className='col-2'>{showDrawingDate({ config, promoName, drawing, drawingGroupName })}</div>
            <div className='col-2'>
                {drawing.drawing_summaries && drawing.drawing_summaries.length
                    ? drawing.drawing_summaries.map(summary => {
                          return summary.entry_count ? summary.entry_count : 0;
                      })
                    : 0}
            </div>
        </div>
    );
};

const showDrawingDate = ({ config, promoName, drawing }) => {
    const drawDate = new Date(drawing.draw_date * 1000);

    if (Date.now() >= drawDate) {
        return (
            <WinnersPortalLink urlBase={config?.config?.promotion_uri} promoName={promoName}>
                {getText('winner_link_viewwinners')}
            </WinnersPortalLink>
        );
    } else {
        return drawDate.toDateString();
    }
};
